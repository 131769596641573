@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "DuckName";
  src: url('../Duckname.ttf');
}

body{
  margin: 0;
  overflow-x: hidden;
  background: #9945FF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #9945FF, #14F195);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #9945FF, #14F195); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #000;
  font-family: "DuckName";
}

.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.logos-horizontal{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  
}

.logos-horizontal img {
  margin-right: 20px; /* Adjust the margin value as needed */
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 20px;
  font-size: 35px;

}

.header img {
  margin-left: 50px; /* Adjust the margin value as needed */
  margin-top: 50px;
}

.header p {
  font-size: 40px;
  margin-top: -30px;
}

.text {
  width: 350px;
}

.custom-btn {
  width: 318px;
  height: 61px;
  color: #fff;
  border-radius: 23px;
  padding: 10px 25px;
  font-family: 'Walt Font';
  font-weight: 500;
  font-size: 1rem;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  z-index: 2;
}

.btn-6 {
  background: rgb(247,150,192);
background: radial-gradient(circle, rgba(247,150,192,1) 0%, rgba(118,174,241,1) 100%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 1px;
 box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover{
  background: transparent;
  color: #76aef1;
  box-shadow: none;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  box-shadow:
   -1px -1px 20px 0px rgba(255,255,255,1),
   -4px -4px 5px 0px rgba(255,255,255,1),
   7px 7px 20px 0px rgba(0,0,0,.4),
   4px 4px 5px 0px rgba(0,0,0,.3);
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: .5px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}

.info{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}

.info img {
  margin-top: 50px;
  margin-right: 20px; /* Adjust the margin value as needed */
}

.memes{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}

.memes img {
  margin-right: 20px; /* Adjust the margin value as needed */
}

.pinksale{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.pinksale img {
  width: 500px;
  margin-right: 20px; /* Adjust the margin value as needed */
}

@media (max-width: 768px) {
  .logos-horizontal {
    flex-direction: column;
    align-items: center;
  }

  .logos-horizontal img {
    margin-bottom: 20px; /* Adjust the margin value as needed */
    margin-right: 0; /* Reset margin for vertical layout */
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .header img{
    margin-left: 0;
  }

  .info {
    flex-direction: column;
    align-items: center;
  }

  .memes {
    flex-direction: column;
    align-items: center;
  }

  .pinksale img {
    width: 300px;
    margin-right: 0px; /* Adjust the margin value as needed */
  }
}
